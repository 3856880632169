import './notFound.css';

const NotFound = (props) => {
    return(
        <div className='page not-found'>
            Page not found
        </div>
    )
};

export default NotFound;
